@import url('https://fonts.googleapis.com/css2?family=Caprasimo&family=Wix+Madefor+Display:wght@400..800&display=swap');

body{
    font-family: "Wix Madefor Display", sans-serif;
    font-weight: 600;
    background-color: #ededed;
}
.navbar-brand {
    font-family: 'Caprasimo', sans-serif;
    font-weight: 400;
    font-style: normal;
}
.nav-link{color: #fff;}
.bg-fluid {
    background-color: #21D4FD;
    background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);
    position: absolute;
    top: 0;
    width: 100%;
    height: 45vh;  
    z-index: -1;  
}
.bg-ht-adj{height: 20vh; }
.vh-75 {
    height: 75vh !important;
}
.slick-prev:before, .slick-next:before{
    color: #ffffff;
}
.slick-dots li button:before{
    color: #fff;
    font-size: 8px;
}
.float-shortcode{
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #E91E63;
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-bottom-right-radius: 0.2rem;
    font-size: 0.8rem;    
}
.match-fixtures .list-group-item .match-date{font-size: 0.7rem;}
.match-fixtures .list-group-item .match-venue{font-size: 0.8rem;}
.match-fixtures .list-group-item .team-title{font-size: 0.95rem;}
.match-live{
    position: absolute;
    top: 0;
    right: 0;
    background-color: #f44336;
    color: #fff;
    padding: 0.2rem 0.5rem;
    font-size: 0.75rem;
    border-top-right-radius: 0.2rem;
}
.match-summary{
    position: absolute;
    top: 0;
    right: 0;
    background-color: #e91e63;
    color: #fff;
    padding: 0.2rem 0.5rem;
    font-size: 0.75rem;
    border-top-right-radius: 0.2rem;
}
.total-sixes{
    background-color: #9C27B0;
    height: 6vh;
    justify-content: center;
    align-items: center;
}
.total-fours{
    background-color: #21D4FD;
    height: 6vh;
    justify-content: center;
    align-items: center;
}
.table-sm{font-size: 0.8rem;}
.nav-pills{display: flex;}
.nav-pills .nav-item{flex: 1 1 0px;}
.nav-pills .nav-link{
    border: 1px solid #0d6efd;
    color: #0d6efd;
    width: 100%;
    font-size: 0.8rem;
    border-radius: 0;
}
.icon-rotate::before{
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);    
}
.team-dc-w{ background: linear-gradient(45deg, #205da3 0%, #982031 100%); }
.team-gg-w{ background: linear-gradient(45deg, #ecac4f 0%, #e12525 100%); }
.team-mi-w{ background: linear-gradient(45deg, #045faa 0%, #e8c633 100%); }
.team-rcb-w{ background: linear-gradient(45deg, #cc162d 0%, #ecba63 100%); }
.team-upw-w{ background: linear-gradient(45deg, #582559 0%, #fcae1d 100%); }
.ranking-badge{
    position: absolute;
    bottom: 0;
    background-color: #0d6efd;
    font-size: 0.9rem !important;
    padding: 0 0.5rem;
    border-radius: 50%;
    color: #fff;  
    font-weight: 400;  
}
.orange-cap{background-color: orange; color: #fff;}
.purple-cap{background-color: purple; color: #fff;}
.bday-slider .slick-slider .slick-prev { left: 5px; z-index: 9;}
.bday-slider .slick-slider .slick-next { right: 5px; z-index: 9;}
.bday-slider .card{background-color: #3F51B5;}